<script>
import {
  BDropdownItem, BImg, BNavItemDropdown, BFormSelect, BLink, BNavbarNav, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BFormSelect,
    BBadge,
    BLink,
    BNavbarNav,
  },
  methods: {
  },
  computed: {
    userData() {
      return this.$store.state.whitelabelCurrencyNabvar.userInfo
    },
    currencySelect(){
      return  this.$store.state.whitelabelCurrencyNabvar.currency
    },
    currencyData(){
      const currencyOptions = this.userData.wallet.currencies
      const currentCurre = currencyOptions.find(currency => currency.currency === this.currencySelect)
      if(currentCurre){
        return currentCurre
      }else{
        return currencyOptions[0]
      }
    }
  },

}
</script>

<template>
  <div>
    <b-badge
      pill
      variant="info"
      >{{ currencyData.balance | currency({ symbol: currencyData.currency }) }}</b-badge
    >
  </div>
</template>
