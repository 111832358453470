<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="align-items-center flex-grow-1">
      <whitelabels-currencies />
    </div>

    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->

    <!-- Bookmarks Container -->
    <!-- <bookmarks /> -->

    <!-- </div> -->

    <b-navbar-nav class="left-user nav align-items-center ml-auto">
      <user-currencies class="user-currencies" />
      <div class="left-user-sub">
        <locale />
        <dark-Toggler />
        <user-dropdown />
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BBadge,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import UserCurrencies from './components/UserCurrencies.vue'
import WhitelabelsCurrencies from './components/WhitelabelsCurrencies.vue'

export default {
  components: {
    UserCurrencies,
    BBadge,
    BLink,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    WhitelabelsCurrencies,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/mixin.scss';

.user-currencies{
  // position: absolute;
  // right: 0;
  // top:0;
  // transform: translate(10px, -50%);

  // @include r(sm){
  //   position: relative;
  //   transform: none;
  // }
}

.left-user{
  flex-direction: column;
  align-items: flex-end !important;
  gap:5px;
  
  @include r(md){
    flex-direction: row;
    align-items: center !important;
  }

  &-sub{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
  }
}
</style>
